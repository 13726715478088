/** @jsx jsx */
import { jsx } from "theme-ui";

export default function Footer() {
  return (
    <div
      sx={{
        variant: "HFNRegFooter",
      }}
    >
      <p>
        Copyright @ {new Date().getFullYear()} Heartfulness All rights reserved
      </p>
      <p>
        <a sx={{ textDecoration: "none", color: "#fff" }} href="/#">
          Terms of Use
        </a>{" "}
        <a sx={{ textDecoration: "none", color: "#fff" }} href="/#">
          Privacy Policy
        </a>
      </p>
    </div>
  );
}
