/** @jsx jsx */
import { jsx, Image, Box, Flex } from "theme-ui";
import PropTypes from "prop-types";

// components
import Header from "./Header";
import Footer from "./Footer";
import "../assets/styles/index.css";
import Seo from "../components/seo";

// images
import sidebar_banner_img1 from "../assets/images/sidebar-images/sidebar1.png";
import sidebar_banner_img2 from "../assets/images/sidebar-images/sidebar2.png";
import bannerImage from "../assets/images/KanhaM.jpg";

const { initializeIcons } = require("office-ui-fabric-react/lib/Icons");

initializeIcons(
  "https://static2.sharepointonline.com/files/fabric/assets/icons/",
  { disableWarnings: true }
);

export default function Layout({
  children,
  pageName,
  headerTitle,
  sidebarVisible,
  home,
}) {
  return (
    <div sx={{ variant: "HFNReg" }}>
      <Seo title={headerTitle} />
      <Header />
      {/* <HeaderBg title={headerTitle} /> */}
      {home && <Image src={bannerImage} alt="banner image" />}
      <div sx={{ variant: "HFNMainPage" }}>
        <div className="main-wrapper">
          <main className={`${!sidebarVisible ? "main-full-wrapper" : ""}`}>
            <div className={pageName}>{children}</div>
          </main>
          {sidebarVisible ? (
            <div className="sidebar" sx={{ variant: "HFNSidebar" }}>
              <div className="img-wrapper">
                {/* <Link to="https://theheartfulnessway.com/in/" target="_blank">
                  <Image src={sidebar_banner_img1} alt="Heartfulness-Events" />
                </Link> */}
                <a
                  href="https://heartsapp.org"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image src={sidebar_banner_img1} alt="Heartfulness-Events" />
                </a>
                <a
                  href="https://www.heartfulnessmagazine.com/"
                  target="_blank"
                  rel="noreferrer"
                  sx={{ maxWidth: "80%", justifySelf: "center" }}
                >
                  <Image src={sidebar_banner_img2} alt="Heartfulness-Events" />
                </a>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Box sx={{ background: "#0b1722", color: "#fff" }}>
        <Flex
          sx={{
            p: "20px 10%",
            justifyContent: "space-between",
            // borderBottom: "1px solid #b6b6b6",
            flexDirection: ["column", "column", "row"],
            gap: "20px",
            mt: 5,
            mb: 3,
          }}
        >
          <Box>
            <h3 sx={{ mb: 3 }}>Address</h3>
            <p sx={{ fontSize: "14px" }}>
              Kanha Shanti Vanam
              <br />
              D. No: 13-110, Kanha Village, Nandigama Mandal
              <br />
              Ranga Reddy District, Telangana 509325
            </p>
          </Box>
          <Box>
            <h3 sx={{ mb: 3 }}>General Contact Info</h3>
            <p sx={{ fontSize: "14px" }}>
              Email:{" "}
              <a
                sx={{ textDecoration: "none", color: "#fff" }}
                href="mailto:welcome@heartfulness.org"
              >
                welcome@heartfulness.org
              </a>
              <br />
              India wide Toll Free: 1 800 121 DHYANA (3492)
              <br />
              Phone: +91 9133318626
              <br />
              (9am to 7pm India Standard time)
            </p>
          </Box>
          <Box>
            <h3 sx={{ mb: 3 }}>Transport contact</h3>
            <p sx={{ fontSize: "14px" }}>
              Email:{" "}
              <a
                sx={{ textDecoration: "none", color: "#fff" }}
                href="mailto:transport@heartfulness.org"
              >
                transport@heartfulness.org
              </a>
              <br />
              Phone: +91 9133318636
              <br />
              (9am to 7pm India Standard time)
            </p>
          </Box>
        </Flex>
        <Box sx={{ bg: "#050c13" }}>
          <Footer />
        </Box>
      </Box>
    </div>
  );
}

Layout.defaultProps = {
  pageName: "",
  headerTitle: "",
  sidebarVisible: false,
  home: false,
};

Layout.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  pageName: PropTypes.string,
  headerTitle: PropTypes.string,
  sidebarVisible: PropTypes.bool,
  home: PropTypes.bool,
};
