import { tint, darken, lighten, mix } from "@theme-ui/color";
import daypicker from "./daypicker";

export default function adaptTheme(theme) {
  const ret = { ...theme };
  // Buttons
  ret.buttons = {
    variant1: {
      mt: 3,
      width: "100%",
      borderRadius: "30px",
      p: 3,
      bg: "primary",
      cursor: "pointer",
      fontFamily: "body",
      "&:hover": {
        bg: tint("primary", 0.1),
      },
      color: "background",
    },
    variant1a: {
      mt: 3,
      width: "100%",
      borderRadius: "30px",
      p: 3,
      bg: "secondary",
      cursor: "pointer",
      fontFamily: "body",
      "&:hover": {
        bg: tint("secondary", 0.1),
      },
      color: "background",
    },
    variant3: {
      mt: 3,
      width: "100%",
      borderRadius: "30px",
      p: 3,
      bg: "secondary",
      cursor: "pointer",
      fontFamily: "body",
      "&:hover": {
        bg: tint("secondary", 0.1),
      },
      color: "background",
    },
    variant4: {
      bg: darken("background", 0.02),
      color: "text",
      width: "100%",
      borderRadius: 0,
      cursor: "pointer",
      boxShadow:
        "0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2)",
      "&:focus, &:active": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        outline: 0,
      },
      "&:hover": {
        bg: "background",
      },
    },
    variant2: {
      p: 2,
      bg: "secondary",
      cursor: "pointer",
      fontFamily: "body",
      "&:hover": {
        bg: tint("secondary", 0.2),
      },
      color: "background",
    },
    primaryFit: {
      px: 3,
      py: 1,
      bg: "primary",
      cursor: "pointer",
      fontFamily: "body",
      color: "background",
      fontSize: "0.8em",
      borderRadius: "30px",
      "&:hover": {
        bg: darken("primary", 0.2),
      },
    },
    primaryFitDisabled: {
      px: 3,
      py: 1,
      bg: "primary",
      opacity: 0.8,
      fontFamily: "body",
      color: "background",
      fontSize: "0.8em",
      borderRadius: "30px",
    },
    secondaryFit: {
      px: 3,
      py: 1,
      bg: "secondary",
      cursor: "pointer",
      fontFamily: "body",
      color: "background",
      fontSize: "0.8em",
      borderRadius: "30px",
      "&:hover": {
        bg: darken("secondary", 0.2),
      },
    },
    secondaryFitDisabled: {
      px: 3,
      py: 1,
      bg: "secondary",
      opacity: 0.8,
      fontFamily: "body",
      color: "background",
      fontSize: "0.8em",
      borderRadius: "30px",
    },
    mutedFit: {
      px: 3,
      py: 1,
      bg: "muted",
      cursor: "pointer",
      fontFamily: "body",
      color: "secondary",
      fontSize: "0.8em",
      borderRadius: "30px",
      "&:hover": {
        opacity: 0.7,
      },
    },
    variant6: {
      px: 3,
      py: 1,
      bg: darken("background", 0.1),
      cursor: "pointer",
      fontFamily: "body",
      color: "primary",
      fontSize: "0.8em",
      borderRadius: "30px",
      "&:hover": {
        bg: darken("background", 0.2),
        color: darken("primary", 0.2),
      },
    },
    iconButton: {
      background: lighten("primary", 0.2),
      height: 30,
      width: 30,
      p: "0px",
      m: "0px",
      borderRadius: 0,
      color: "text",
      fontFamily: "body",
      cursor: "pointer",
      span: {
        fontSize: "26px",
        lineHeight: "26px",
      },
      "&:hover": {
        bg: lighten("primary", 0.22),
      },
    },
    icon: {
      color: tint("text", 0.5),
      ":hover": { color: tint("text", 0.3) },
      cursor: "pointer",
    },
  };
  // Flex
  ret.flex = {
    avatarWrapper: {
      flexDirection: "column",
      justifyContent: "center",
      bg: "primary",
      color: "background",
      alignItems: "center",
      borderRadius: "50%",
      borderColor: "secondary",
      height: 36,
      width: 36,
      span: {
        fontSize: 16,
      },
    },
    daypickerInput: daypicker,
  };
  // Images
  ret.images = {
    avatar1: {
      height: 36,
      width: 36,
    },
  };
  // Messages
  ret.messages = {
    variant1: {
      borderLeft: `3px solid`,
      borderLeftColor: "errortext",
      padding: "12px",
      bg: "errorbg",
      borderColor: "errorbg",
      color: "errortext",
    },
    variantEW: {
      border: `2px solid`,
      borderColor: "errortext",
      borderRadius: "15px",
      padding: "12px",
      bg: "white",
      color: "errortext",
    },
    variantW: {
      marginLeft: "15px",
      color: "#545454",
    },
  };
  ret.alerts = {
    notify: {
      bg: "highlight",
      color: "text",
      fontWeight: "400",
    },
  };
  // Icons
  ret.icons = {
    variant1: {
      padding: "4px",
      color: "text",
    },
  };
  // Forms
  ret.forms = (() => {
    const baseVariant = {
      border: "none !important",
      borderColor: "primary",
      color: "text",
      outline: "none ",
      borderRadius: `0 !important`,
      fontFamily: "body",
      bg: "background",
      p: ({ space }) => `${space[1]} !important`,
      pr: ({ space }) => `${space[3]} !important`,
      fontSize: ({ fontSizes }) => `${fontSizes[3]} !important`,
    };
    return {
      "border-0": {
        ...baseVariant,
      },
      "border-bottom": {
        ...baseVariant,
        borderBottom: ({ colors }) => `thin ${colors.primary} solid !important`,
        "&:disabled": {
          bg: "muted",
        },
      },
      primary: {
        bg: "primary",
        color: "background",
        lineHeight: "24px",
        fontFamily: "body",
        padding: 2,
        border: ({ colors }) => `solid thin ${colors.background} `,
        borderRadius: 0,
        "&:focus": {
          outline: 0,
        },
        "&::placeholder": {
          color: "background",
          opacity: 0.4,
        },
      },
      ...ret.forms,
    };
  })();
  // Badges
  ret.badges = {
    danger: {
      bg: mix("red", "background", 0.7),
      color: "background",
    },
    warning: {
      bg: mix("red", "background", 0.85),
      color: "background",
    },
    primary: {
      bg: "primary",
      color: "background",
    },
    secondary: {
      bg: "secondary",
      color: "background",
    },
    outline: {
      color: "primary",
      bg: "transparent",
      boxShadow: "inset 0 0 0 1px",
    },
    outlineSecondary: {
      color: "secondary",
      bg: "transparent",
      boxShadow: "inset 0 0 0 1px",
    },
    info: {
      color: "text",
      background: tint("secondary", 0.7),
    },
    ...ret.badges,
  };
  // Text
  ret.text = (() => {
    const bgPrimary = {
      bg: "primary",
    };
    const bgDanger = {
      bg: mix("red", "background", 0.5),
    };
    const bgWarning = {
      bg: mix("red", "background", 0.75),
    };
    const textBg = {
      color: "background",
    };
    const bgSuccess = {
      bg: "success",
    };
    return {
      inverted: {
        ...bgPrimary,
        ...textBg,
      },
      danger: {
        ...bgDanger,
        ...textBg,
      },
      warning: {
        ...bgWarning,
        ...textBg,
      },
      invertedWarning: {
        color: mix("#545454", "primary", 0.75),
      },
      invertedDanger: {
        liStyle: {
          color: mix("errortext", "white", 0.5),
        },
        color: mix("#545454", "primary", 0.9),
      },
      error: {
        color: "errortext",
        fontWeight: 700,
      },
      success: {
        ...bgSuccess,
        ...textBg,
      },
      cardHeader: {
        color: "primary",
        fontWeight: "700",
      },
      header: {
        fontSize: "1.2rem",
        fontWeight: "600",
      },
      title: {
        fontSize: "1rem",
        fontWeight: "600",
      },
      subtitle: {
        fontSize: "0.9rem",
        fontWeight: "300",
        opacity: 0.9,
      },
      sectionTitle: {
        fontSize: "0.8rem",
        fontWeight: "600",
      },
      printFormLabel: {
        fontSize: "0.7rem",
        fontWeight: "300",
        fontStyle: "italic",
        opacity: 0.9,
      },
      printFormValue: {
        fontSize: "0.9rem",
        fontWeight: "400",
        opacity: 1.0,
      },
      description: {
        fontSize: "0.8rem",
        fontWeight: "300",
        opacity: 0.5,
      },
      h4: {
        fontWeight: "400",
        my: 1,
      },
      ...ret.text,
    };
  })();
  // Boxes
  ret.boxes = {
    ...ret.boxes,
    primaryCheckbox: {
      display: "flex",
      cursor: "pointer",
      flexDirection: "column",
      justifyContent: "center",
      "input ~ svg, input:focus ~ svg": {
        color: "background",
        bg: "primary",
      },
      "input:checked ~ svg": {
        color: "background",
        bg: "primary",
      },
    },
    primaryRadio: {
      "input ~ svg, input:checked ~ svg": {
        bg: "primary",
        path: {
          fill: "background",
        },
      },
      "input:focus ~ svg": {
        bg: "primary",
        color: "background",
        path: {
          fill: "background",
        },
      },
    },
    primaryPhoneInput: {
      ".react-tel-input": {
        borderColor: "background",

        ".flag-dropdown": {
          bg: "white",
          borderColor: "background",
          ".selected-flag": {
            borderRadius: 0,
            bg: "white",
          },
          "li.search": {
            padding: "5px",
            display: "flex",
            boxShadow: "1px 1px 1px 1px rgba(0,0,0, .2)",
            "span.search-emoji": {
              display: "flex",
              alignItems: "center",
            },
            "input.search-box": {
              margin: 0,
              background: "white",
              borderRadius: "0",
              padding: "5px",
              lineHeight: "16px",
              fontSize: "16px",
              color: "black",
              "&::placeholder": {
                color: "rgba(0,0,0,.3)",
              },
            },
          },
        },
        //
        input: {
          height: "auto",
          paddingTop: 2,
          paddingBottom: 2,
          lineHeight: "24px",
          borderColor: "background",
          borderRadius: 0,
          width: "100%",
          bg: "primary",
          color: "background",
          "&::placeholder": {
            color: "background",
            opacity: 0.4,
          },
        },
      },
    },
    fileinput: {
      bg: "background",
      border: "1px black solid",
      borderColor: "primary",
      label: { color: "background", fontSize: 1, pb: 1 },
      ".field-wrapper": {
        justifyContent: "space-between",
        alignItems: "center",
        svg: {
          color: "primary",
          height: "20px",
          width: "20px",
        },
      },
      div: {
        bg: "background",
        padding: 2,
        color: "text",
      },
    },
    autosuggest: {
      paddingTop: "2px",

      ".react-autosuggest__container": {
        position: "relative",
      },

      ".react-autosuggest__suggestions-container": {
        display: "none",
      },

      ".react-autosuggest__suggestions-container--open": {
        display: "block",
        position: "absolute",
        top: "30px",
        width: "280px",
        border: "1px solid #aaa",
        backgroundColor: "background",

        color: "text",
        fontFamily: '"Open Sans", sans-serif',
        fontWeight: 300,
        fontSize: "16px",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
        zIndex: 2,
      },

      ".react-autosuggest__suggestions-list": {
        margin: 0,
        padding: 0,
        listStyleType: "none",
      },

      ".react-autosuggest__suggestion": {
        cursor: "pointer",
        padding: "10px 20px",
      },

      ".react-autosuggest__suggestion--highlighted": {
        bg: "secondary",
        color: "white !important",
      },

      ".react-autosuggest__section-container": {
        borderTop: "1px dashed #ccc",
      },

      ".react-autosuggest__section-container--first": {
        borderTop: 0,
      },
    },
    bgBox: {
      bg: "background2",
      color: "primary",
    },
  };
  // Links
  ret.links = {
    variant1: {
      borderColor: "background",
      borderStyle: "solid",
      borderWidth: "thin",
      p: 2,
      m: 1,
      ml: 2,
      bg: "primary",
      fontWeight: "heading",
      color: "background",
      "&:hover": {
        color: "background",
      },
    },
  };

  return ret;
}
