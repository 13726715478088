/** @jsx jsx */
import { jsx, Image, Flex } from "theme-ui";
import { Link } from "gatsby";
import AppIcons from "sites-common/utils/icons";
import { Avatar, SignInButton } from "gatsby-plugin-hfn-profile/auth";

export default function Header() {
  return (
    <div sx={{ variant: "HFNRegHeader" }}>
      <div className="logo-section" sx={{ py: "15px" }}>
        <div className="logo-img">
          <Link to="/">
            <Image
              sx={{ px: 3 }}
              width="160"
              src={AppIcons.logo}
              alt="Heartfulness"
            />
          </Link>
        </div>
      </div>
      <div />
      <Flex sx={{ gap: "20px", position: "relative", alignItems: "center" }}>
        <SignInButton btnText="Login in" />
        <Avatar />
      </Flex>
    </div>
  );
}
